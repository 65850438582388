body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.cbp-navbar {
  /* border-top: solid 10px #0d6efd; */
  box-shadow: 0px 0px 10px 0px grey;
  margin-bottom: 0.5rem;
}

.app-container {
  padding: 0px 20px;
}

.displayInlineBlock {
  display: inline-block;
}

.prod-selection {
  vertical-align: text-bottom !important;
  margin-left: 20px;
}

.grid-hour-row-container {
  display: flex;
  width: 1500px;
}

.grid-hour-row, .grid-header-hour {
  display: inline-block;
  /* min-width: 200px; */
  min-width: 100px;
  padding: 13px 0px;
  text-align: center;
  height: 45px;
  font-size: 0.75rem;
  background: #fff;;
}

.grid-date-row {
  display: inline-block;
  min-width: 1500px;
  width: 1500px;
  padding: 5px 10px;
  height: 35px;
  font-size: 0.9rem;
  background: #eef5ff;
}

.grid-hour-row {
  height: 100px;
}

.grid-header-right-container {
  overflow-x: auto;
  padding-left: 0px !important;
  padding-right: 0px !important;
  height: 82vh;
  scroll-behavior: smooth;  
}

.px-0 .col {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.date-turf-cell {
  width: 90px !important;
  min-width: 90px !important;
}

.date-turf-first-cell {
  font-size: 12px;
  padding: 7px 0px;
  text-align: center;
  background: #eef5ff !important;
  font-weight: bold;
}

.date-turf-second-cell {
  width: 1800px;
  min-width: 1800px;
}

.grid-turf-hour {
  display: inline-block;
  /* min-width: 200px; */
  min-width: 100px;
  padding: 2px;
  text-align: center;
  height: 50px;
  font-size: 0.8rem; 
}

.cell-border {
  border-left: solid 1px #dee2e6;
  border-right: solid 1px #dee2e6;
  border-bottom: solid 1px #dee2e6;
}

.turf-name {
  padding-top: 15px;
}

.add-btn {
  color: #e5e5e5;
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

.fs-small {
  font-size: small;
}

.turf-booking {
  text-align: left;
  height: 100%;
  background: #ededed;
  border-radius: 5px;
  width: 100%;
  background: #FFE1E1;
  position: relative;
  /* padding: 5px 10px; */
}

.turf-booking .name {
  background: #FFC7C7;
  padding: 2px 5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 0.65rem;
}

.turf-booking .body {
  padding: 1px;
}

.turf-booking .phonenumber {
  font-size: 0.6rem;
  background-color: #FFC7C7;
  border-radius: 5px;
  display: inline-table;
  cursor: pointer;
}

.turf-booking .phonenumber svg {
  margin-right: 5px;
  margin-left: 5px;
  vertical-align: text-bottom;
}

.turf-booking .payment {
  font-size: 0.5rem;
  background-color: #FFCF71;
  border-radius: 5px;
  margin-top: 2px !important;
}

.turf-booking .member {
  font-size: 0.6rem;
  background-color: #C4BAFF;  
  border-radius: 5px;
  float: left;
  margin-top: 1px;
  padding: 2px 5px !important;
}

.is-member {
  background-color: #8d79ff;
}

.turf-booking .note {
  float: right;
  margin-top: 3px;
}

.text-error {
  color: red;
}

.add-membership svg {
  color: #0d6efd;
}

.cursor-pointer {
  cursor: pointer;
}

.red {
  background-color: red !important;
  color: #fff;
}

.green {
  background-color: green !important;
  color: #fff;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.sticky-top {
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 9;
}

.sticky-left {
  position: sticky;
  left: 0;
  background: #fff;
  z-index: 9;
}

.float-right {
  float: right;
}

.prev-next {
  font-size: 1.5rem;
  vertical-align: top;
}

.user-button .nav-link, .user-button .nav-link.show {
  color: #fff !important;
}

.user-button .dropdown {
  z-index: 99999;
}

.user-button .nav-link::after {
  margin-left: 1rem;
}

